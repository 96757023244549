import {Controller} from "@hotwired/stimulus";
import Preloader from '../../components/preloader';

export default class extends Controller {
    static values = {
        refreshUrl: String
    }

    connect() {
        $('[data-ajax]').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            const $this = $(e.target);

            if ($this.attr('data-after-click')) {
                $this.addClass($this.attr('data-after-click'));
            }

            $.ajax({
                type: "GET",
                url: $this.attr('href'),
                success: function (data) {

                },
                error: function() {
                    Preloader.remove();
                }
            });
        });
    }
}