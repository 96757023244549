import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        document.getElementById('send-form-button').onclick = function () {
            let button = document.getElementById('send-form-button');
            let h4 = document.createElement('h4');
            let message = document.createElement('p');
            let a = document.getElementById('google-link');
            let xhrUpload = new XMLHttpRequest();
            let modal = document.getElementById('modal');
            let modalBody = document.getElementsByClassName('modal-body')[0];
            let modalFooter = document.getElementsByClassName('modal-footer')[0];
            var loading = document.getElementById('loading');

            h4.innerText = 'Обновление прогноза ВД';
            message.innerText = 'Прогноз ВД выгружается в Google Drive. Это может занять время. После завершения вы сможете открыть обновлённый файл.'
            modalBody.innerHTML = '';
            modalBody.append(h4, message);
            modal.className = 'modal fade show';
            modal.style.display = 'block';
            a.style.display = 'none';
            loading.style.display = 'inline-block';
            button.disabled = true;

            xhrUpload.open('POST', '/admin/gross_income_updater');
            xhrUpload.send();
            xhrUpload.onload = function () {
                h4.innerText = 'Обновление завершено';
                message.innerText = 'Прогноз ВД успешно обновлён в Google Drive. Нажмите кнопку ниже, чтобы открыть прогноз.'
                modalBody.innerHTML = '';
                modalBody.append(h4, message);
                modalFooter.append(a);
                modal.className = 'modal fade show';
                modal.style.display = 'block';
                a.style.display = 'block';
                loading.style.display = 'none';
                button.disabled = false;
            };
            xhrUpload.onerror = function () {
                message.innerText = 'Процесс обновления ВД не выполнен.'
                message.style.color = 'red';
                modalBody.innerHTML = '';
                modalBody.append(h4, message);
                modalFooter.append(a);
                modal.className = 'modal fade show';
                modal.style.display = 'block';
                loading.style.display = 'none';
                button.disabled = false;
            };
        };

        document.querySelector('[data-click="close"]').onclick = function () {
            let modal = document.getElementById('modal');
            modal.className = 'modal fade';
            modal.style.display = 'none';
        }
    }
}