import {Controller} from "@hotwired/stimulus";
import tippy from "tippy.js";

/**
 * Обработчик добавления/удаления Медиаплана в/из Избранного.
 */
export default class extends Controller {
    static values = {
        url: String
    }

    async switch() {
        this.element.classList.add('disabled');

        let response = await fetch(this.urlValue, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });

        if (response.status === 200) {
            const marked = await response.json();

            const icon = this.element.querySelector('i');

            let title = '';
            if (marked) {
                icon.className = 'fa fa-star';
                title = 'Убрать из избранного'
            } else {
                icon.className = 'far fa-star';
                title = 'Добавить в избранное';
            }

            this.element._tippy.destroy();

            tippy(this.element, {
                content: title,
                allowHTML: true
            });
        }

        this.element.classList.remove('disabled');
    }
}